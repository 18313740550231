// Generated by Framer (2c4b293)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VveCfwzHr"];

const serializationHash = "framer-h8nlF"

const variantClassNames = {VveCfwzHr: "framer-v-f8wvmy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, wTk51xjm1: image ?? props.wTk51xjm1 ?? {src: "https://framerusercontent.com/images/4yEoLtOjz7ESuqbUM87uaJEmpw.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/4yEoLtOjz7ESuqbUM87uaJEmpw.png?scale-down-to=512 512w,https://framerusercontent.com/images/4yEoLtOjz7ESuqbUM87uaJEmpw.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/4yEoLtOjz7ESuqbUM87uaJEmpw.png 1344w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wTk51xjm1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VveCfwzHr", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-f8wvmy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VveCfwzHr"} ref={ref ?? ref1} style={{backgroundColor: "rgb(241, 241, 241)", borderBottomLeftRadius: 21, borderBottomRightRadius: 21, borderTopLeftRadius: 21, borderTopRightRadius: 21, ...style}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 12 + (((((componentViewport?.height || 139) - 24) - 114) / 2) + 0 + 0))), sizes: "253px", ...toResponsiveImage(wTk51xjm1), ...{ positionX: "center", positionY: "center" }}} className={"framer-39x98s"} layoutDependency={layoutDependency} layoutId={"MYxVQuzq0"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h8nlF.framer-1sfpa0s, .framer-h8nlF .framer-1sfpa0s { display: block; }", ".framer-h8nlF.framer-f8wvmy { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 139px; justify-content: center; padding: 12px; position: relative; width: 277px; }", ".framer-h8nlF .framer-39x98s { flex: none; height: 114px; overflow: visible; position: relative; width: 253px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-h8nlF.framer-f8wvmy { gap: 0px; } .framer-h8nlF.framer-f8wvmy > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-h8nlF.framer-f8wvmy > :first-child { margin-top: 0px; } .framer-h8nlF.framer-f8wvmy > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 139
 * @framerIntrinsicWidth 277
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wTk51xjm1":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSYyD0J2z4: React.ComponentType<Props> = withCSS(Component, css, "framer-h8nlF") as typeof Component;
export default FramerSYyD0J2z4;

FramerSYyD0J2z4.displayName = "replies";

FramerSYyD0J2z4.defaultProps = {height: 139, width: 277};

addPropertyControls(FramerSYyD0J2z4, {wTk51xjm1: {__defaultAssetReference: "data:framer/asset-reference,4yEoLtOjz7ESuqbUM87uaJEmpw.png?originalFilename=Screenshot+2024-09-09+at+09.08.49.png&preferredSize=auto", description: "change the image", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerSYyD0J2z4, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})